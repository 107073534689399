import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UnifiedThemeOptions } from "@backstage/theme";
import { appThemeApiRef, useApi } from "@backstage/core-plugin-api";
import CloudCircleIcon from "@material-ui/icons/CloudCircle";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CloudIcon from "@material-ui/icons/Cloud";
import { Grid, Link, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Dialog from '@material-ui/core/Dialog';
import useWindowDimensions from '../../hooks/UseWindowDimensions';

type CardShelfProps = {
  id: number;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  text: string;
  link?: string;
  description: string;
  videoLink?: string;
};


const useStyles = makeStyles<UnifiedThemeOptions>((theme) => ({
  htmlColor: {
    icon: theme.palette.type === "dark" ? "#ffffff" : "#00283D",
  },
  row: {
    padding: "0 30px 10px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "180px",
    textAlign: "center",
    borderRadius: "25px",
    overflow: "hidden",
    color: theme.palette.type === "dark" ? "#ffffff" : "#00283D",
    "& svg": {
      fontSize: "100px",
      minHeight: "115px",
    },
  },
  cardLink: {
    display: "block",
    textDecoration: "none",
    height: "100%",
    "& em": {
      visibility: "hidden",
      opacity: "0.8",
      marginBottom: "-10px",
      color: theme.palette.type === "dark" ? "#ffffff" : "#00283D",
    },
    "&:hover em, &:focus em": {
      visibility: "visible",
      textDecoration: "none",
    },
    "&:hover, &:focus": {
      textDecoration: "none",
      transform: "scale(1.025)",
      transition: "0.2s",
      position: "relative",
      borderRadius: "25px",
      "& svg": {
        color: "#F49F0A",
      },
    },
    "& > div": {
      height: "100%",
    },
  },
  cardBorder: {
    borderRadius: "25px",
    overflow: "hidden",
  },

}));

function CardShelfComponent({
  id,
  Icon,
  text,
  link,
  videoLink,
  description,
}: Readonly<{
  id: any
  Icon: any;
  text: any;
  link?: any;
  videoLink?: any;
  description: any;
}>): React.JSX.Element {
  const classes = useStyles();
  const activeTheme = useApi(appThemeApiRef).getActiveThemeId();
  const [open, setOpen] = React.useState(false);
  const { width, height } = useWindowDimensions();
  console.log("Screen-height=", height, "\t", "Screen-Width=", width, "\t", "Video-Width=", width * 0.74, "\t", "Video-height=", height * 0.8)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://web.microsoftstream.com/embed/video/video-id';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      {(link !== undefined) ?
        (<Link href={link} id={id} className={classes.cardLink}>
          <Card id={id} className={classes.card}>
            <CardContent id={id} className={classes.card}>
              <Icon
                id={id}
                fontSize='inherit'
                htmlColor={
                  activeTheme === "dark"
                    ? "#ffffff"
                    : "#00283D"
                }
              />
              <span id={id} >{text}</span>
              <em id={id}  >{description}</em>
            </CardContent>
          </Card>
        </Link>
        ) : (
          <>

            <Link id={id} onClick={handleClickOpen} className={classes.cardLink}>
              <Card id={id} className={classes.card}>
                <CardContent id={id} className={classes.card}>
                  <Icon
                    id={id}
                    fontSize='inherit'
                    htmlColor={
                      activeTheme === "dark"
                        ? "#ffffff"
                        : "#00283D"
                    }
                  />
                  <span id={id} >{text}</span>
                  <em id={id}  >{description}</em>
                </CardContent>
              </Card>
            </Link>
            <Dialog
              fullWidth={false}
              maxWidth="xl"
              onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
              <iframe src={videoLink}
                style={{ border: "0" }}
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='Cloud9 Muziris'
                width={width * 0.74} height={height * 0.8}
              />

            </Dialog>

          </>
        )}
    </>
  );
}

const List = [
  {
    id: 1,
    Icon: CloudIcon,
    text: "BrightCloud / Cloud Native",
    link: "/create/templates/default/eco-system-builder-template-cn",
    description:
      "Deploy a webapp, apis & micro-services on a managed Azure Appservice instance",
  },
  {
    id: 2,
    Icon: CloudCircleIcon,
    text: "Kubernetes (AKS)",
    link: "/create/templates/default/eco-system-builder-v2",
    description: "Build an enterprise connected app ready for Kubernetes",
  },
  {
    id: 3,
    Icon: PlayCircleFilledWhiteIcon,
    text: "Muziris Quick Tour",
    videoLink: "https://mnscorp.sharepoint.com/sites/CloudEngineering/_layouts/15/embed.aspx?UniqueId=2398ed4c-af05-4463-ab30-2a2e4b6674b5&embed=%7B%22af%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
    description:
      "Get to know Muziris in two minutes",
  },
  {
    id: 4,
    Icon: MenuBookIcon,
    text: "Cloud9 Handbook",
    link: "/docs/default/Component/Cloud9",
    description:
      "Learn and master the Cloud9 capabilities and unlock the potential of cloud in M&S",
  },
];

export const CardShelf = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.row} container spacing={2} id='cards'>
      {List.map((props) => (
        <Grid item xs={3} key={props.id}>
          <CardShelfComponent key={props.id} {...props as CardShelfProps} />
        </Grid>
      ))}
    </Grid>
  );
};


